import React, { useRef } from "react"
import Img from "gatsby-image"
import cx from "classnames"
import styles from "./storyhero.module.scss"
import useWindowResize from "../../hooks/use-window-resize"


/**
 * Full-width hero with carousel of background images and text above/below logo
 */
const StoryHero = ({ primary }) => {
  const {
    hero_content, 
    hero_social_header, 
    hero_image,
    hero_video,
    insta_social_text,
    insta_link,
    apple_social_text,
    apple_link,
    medium_social_text,
    medium_link,
    vimeo_social_text,
    vimeo_link
  } = primary
  const logotype = useRef()
  const section = useRef()

  // flexbox polyfill
  // vertical align logotype
  useWindowResize(() => {
    if (section.current && logotype.current) {
      const hContainer = section.current.clientHeight
      const hLogo = logotype.current.clientHeight
      const offset = (hContainer - hLogo) / 2
      const top = `${offset}px`
      logotype.current.style.top = top
    }
  })

  return (
    <section
      className={cx(
        styles.storyHero,
      )}
      ref={section}
    >
      <div className="grid-container full" style={{ maxWidth: "1280px"}}>
        <div className="grid-x grid-padding-x" style={{alignItems:"center", justifyContent:"space-between"}}>
          <div className="cell large-6" >
            <div className={cx("grid-x",styles.copy)}>
              <div class="cell large-10 medium-8"
                dangerouslySetInnerHTML={{ __html: hero_content.html }}
              />
              <div className={styles.smallGrid}>
                <div className="grid-x">
                  <div className={cx("cell large-12", styles.smallGrid__header)}>
                    <h4>{hero_social_header}</h4>
                  </div>
                </div>
                <div className="grid-x">
                  <div className={cx("cell small-12 medium-6", styles.smallGrid__item)}>
                    <a target="_blank" rel="noopener noreferrer" href={insta_link?.url}><h5 className={styles.insta}>{insta_social_text}</h5></a>
                  </div>
                  <div className={cx("cell small-12 medium-6", styles.smallGrid__item)}>
                    <a target="_blank" rel="noopener noreferrer" href={apple_link?.url}><h5 className={styles.apple}>{apple_social_text}</h5></a>
                  </div>
                  <div className={cx("cell small-12 medium-6", styles.smallGrid__item)}>
                    <a target="_blank" rel="noopener noreferrer" href={medium_link?.url}><h5 className={styles.medium}>{medium_social_text}</h5></a>
                  </div>
                  <div className={cx("cell small-12 medium-6", styles.smallGrid__item)}>
                    <a target="_blank" rel="noopener noreferrer" href={vimeo_link?.url}><h5 className={styles.vimeo}>{vimeo_social_text}</h5></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cell large-6">
            <div dangerouslySetInnerHTML={{ __html: hero_video?.html }} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default StoryHero
