import React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import UnderlinedTitle from "../components/UnderlinedTitle"
import FAQ from "../components/FAQ"
import MediumWidget from "../components/MediumWidget"
import FullWidthImage from "../components/FullWidthImage"
import Partners from "../components/Partners"
import RichText from "../components/RichText/richtext"
import EmbeddedContent from "../components/EmbeddedContent"
import About from "../components/About"
import SocialButtons from "../components/SocialButtons"
import Hero from "../components/Hero"
import InstagramFeed from "../components/InstagramFeed"
import MusicMediaEmbed from "../components/MusicMediaEmbed"
import MusicMediaBlock from "../components/MusicMediaBlock"
import Button from "../components/Button"
import EmbedCarousel from "../components/EmbedCarousel"
import Title from "../components/Title"
import ApplePodcastEmbed from "../components/ApplePodcastEmbed"

import StoryHero from "../components/StoryHero"
import Featured from "../components/Featured"
import FeaturedStory from "../components/FeaturedStory"
import StoryTabs from "../components/StoryTabs"
import VideoStories from "../components/VideoStories"
import PodcastStories from "../components/PodcastStories"
import ArticleStories from "../components/ArticleStories"

const Page = ({ data }) => {
  const sliceComponents = {
    PrismicPageBodyUnderlinedTitle: UnderlinedTitle,
    PrismicPageBodyFaq: FAQ,
    PrismicPageBodyMediumWidget: MediumWidget,
    PrismicPageBodyFullWidthImage: FullWidthImage,
    PrismicPageBodyPartnersAirtable: Partners,
    PrismicPageBodyJuicerFeed: InstagramFeed,
    PrismicPageBodyRichtext: RichText,
    PrismicPageBodyEmbeddedContent: EmbeddedContent,
    PrismicPageBodyAbout: About,
    PrismicPageBodySocialButtons: SocialButtons,
    PrismicPageBodyHero: Hero,
    PrismicPageBodyMediaMusicEmbed: MusicMediaEmbed,
    PrismicPageBodyMediaMusicBlock: MusicMediaBlock,
    PrismicPageBodyButton: Button,
    PrismicPageBodyVideoCarousel: EmbedCarousel,
    PrismicPageBodyTitle: Title,
    PrismicPageBodyApplePodcastsEmbed: ApplePodcastEmbed,
    PrismicPageBodyStoryHero: StoryHero,
    PrismicPageBodyFeatured: Featured,
    PrismicPageBodyFeaturedStory: FeaturedStory,
    PrismicPageBodyStoryTabs: StoryTabs,
    PrismicPageBodyVideoStories: VideoStories,
    PrismicPageBodyPodcastStories: PodcastStories,
    PrismicPageBodyArticleStories: ArticleStories,
  }
  const pageData = data.prismicPage.data
  return (
    <Layout>
      <SEO
        title={pageData.title.text}
        description={pageData.meta_description.text}
        image={pageData.meta_image.url}
      />
      {pageData.body.map(({ __typename, ...props }, i) => {
        if (sliceComponents[__typename])
          return React.createElement(sliceComponents[__typename], {
            ...props,
            // eslint-disable-next-line react/no-array-index-key
            key: `${__typename}-${i}`,
          })
        return <p key={__typename}>TODO: {__typename}</p>
      })}
    </Layout>
  )
}

export const query = graphql`
  query pageQuery($id: String!) {
    prismicPage(id: { eq: $id }) {
      data {
        meta_description {
          text
        }
        meta_image {
          url
        }
        title {
          text
        }
        body {
          ... on PrismicPageBodyHero {
            primary {
              above_title {
                text
              }
              above_subtitle {
                html
              }
              logo {
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              logo_label
              below_title {
                text
              }
              below_subtitle {
                text
              }
              size
              subtitle_size
            }
            items {
              image {
                fluid(maxWidth: 2000) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              image_label
            }
          }
          ... on PrismicPageBodyFullWidthImage {
            primary {
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              label {
                text
              }
            }
          }
          ... on PrismicPageBodyFaq {
            primary {
              toc_title {
                text
              }
            }
            items {
              answer {
                html
              }
              question {
                text
              }
              group_name
            }
          }
          ... on PrismicPageBodyUnderlinedTitle {
            primary {
              text {
                text
              }
              bold
            }
          }
          ... on PrismicPageBodySocialButtons {
            id
            primary {
              above {
                html
              }
              below {
                html
              }
            }
            items {
              color
              link {
                url
                target
              }
              logo
              logo_label
            }
          }
          ... on PrismicPageBodyEmbeddedContent {
            primary {
              embed {
                embed_url
                height
                html
                width
                url
                title
              }
              title {
                text
              }
            }
          }
          ... on PrismicPageBodyMediumWidget {
            primary {
              author
              claps
              use_description
              fans
              image_size
              limit
              medium_account_link {
                url
              }
              medium_title {
                text
              }
              posts_per_line
              publication_date
            }
          }
          ... on PrismicPageBodyJuicerFeed {
            primary {
              heading {
                text
              }
              view_all_text
              view_all_link{
                url
              }
            }
          }
          ... on PrismicPageBodyRichtext {
            primary {
              richtext {
                html
              }
            }
          }
          ... on PrismicPageBodyPartnersAirtable {
            primary {
              cscnc_heading {
                text
              }
              cscnc_description {
                html
              }
              historical_partners_heading {
                text
              }
            }
          }
          ... on PrismicPageBodyAbout {
            id
            primary {
              description {
                html
              }
              title {
                text
              }
            }
          }
          ... on PrismicPageBodyMediaMusicEmbed {
            primary {
              content_embed {
                html
                description
              }
            }
          }
          ... on PrismicPageBodyMediaMusicBlock {
            primary {
              block_embed {
                html
                description
              }
              optional_image {
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              embed_location
              block_content {
                html
              }
              optional_image_link {
                url
              }
            }
          }
          ... on PrismicPageBodyButton {
            primary {
              margin_top
              margin_bottom
              button_css_classes
              button_label {
                text
              }
              button_link {
                url
              }
            }
          }
          ... on PrismicPageBodyVideoCarousel {
            items {
              embed_label
              description {
                html
              }
              carousel_embed {
                html
                title
              }
            }
          }
          ... on PrismicPageBodyTitle {
            primary {
              text {
                text
              }
              bold
            }
          }
          ... on PrismicPageBodyApplePodcastsEmbed {
            primary {
              podcast_title {
                text
              }
              embed_link {
                url
              }
            }
          }
          ... on PrismicPageBodyStoryHero {
            primary {
              hero_content {
                html
              }
              hero_social_header
              hero_video{
                embed_url
                html
              }
              insta_social_text
              insta_link{
                url
              }
              apple_social_text
              apple_link{
                url
              }
              medium_social_text
              medium_link{
                url
              }
              vimeo_social_text
              vimeo_link{
                url
              }
            }

          }
          ... on PrismicPageBodyFeatured {
            primary {
              section_image{
                fluid(maxWidth: 172) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              section_header
              section_content{
                html
              }
            }
          }
          ... on PrismicPageBodyFeaturedStory {
            primary {
              section_header
              story_content{
                html
              }
              embd_media{
                embed_url
              }
            }
          }
          ... on PrismicPageBodyStoryTabs {
            primary{
              videos_tab_header
              podcasts_tab_header
              articles_tab_header
              instagram_tab_header
            }
          }
          ... on PrismicPageBodyPodcastStories {
            primary{
              section_header
              embed_link{
                url
              }
            }
          }
          ... on PrismicPageBodyVideoStories {
            primary{
              section_header
              view_all_text
              view_all_link{
                url
              }
              project_shorts_text
              project_shorts_link{
                url
              }
              project_docs_text
              project_docs_link{
                url
              }
            }
          }
          ... on PrismicPageBodyArticleStories {
            primary{
              section_header
              button_text
            }
          }
        }
      }
    }
  }
`
export default withPreview(Page)
