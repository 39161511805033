import React from "react"
import styles from "./featured.module.scss"
import cx from "classnames"
import Img from "gatsby-image"
/**
 * Renders title text and description.
 */
const Featured = ({ primary}) => {
  const { section_header, section_content, section_image } = primary
  return (
    <section className={styles.featured}>
      <div className={cx("grid-container",styles.container)} style={{ maxWidth: "1280px"}}>
        <div className="grid-x" style={{alignItems:"center", flexWrap:"wrap-reverse", justifyContent:"space-between"}}>
          <div className={cx("cell medium-4", styles.shapeBox)}>
            <div>
              <h5>{section_header}</h5>
              <Img
                className={styles.image}
                fluid={section_image.fluid}
                alt=""
                aria-label=""
                role="img"
                loading="eager"
              />
            </div>
          </div>
          <div className={cx("cell medium-6", styles.copyWrap)}>
            <div
              className={styles.copy}
              dangerouslySetInnerHTML={{ __html: section_content.html }}
            />
          </div>
          
        </div>

        
      </div>
    </section>
  )
}

export default Featured
